import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { black, white } from 'shared/colors';

const FAQPage = () => {
  return (
    <main className={css(styles.container)}>
      <div className={css(styles.textContainer)}>
        <h1 className={css(styles.h1)}>Frequently Asked Questions (FAQs)</h1>
        <p>Under Construction</p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </main>
  );
};

export default FAQPage;

const styles = StyleSheet.create({
  h1: {
    fontSize: '2rem',
    paddingTop: 240,
    marginBottom: '2rem',
  },
  h2: {
    fontSize: '1.5rem',
    marginBottom: '1.75rem',
    textDecoration: 'underline',
  },
  h3: {
    fontSize: '1.25rem',
    marginBottom: '1.5rem',
  },
  container: {
    backgroundColor: black,
    color: white,
    padding: '0 20px',
  },
  textContainer: {
    fontFamily: 'Titling Gothic FB Normal Regular',
    maxWidth: 856,
    margin: '0 auto',
  },
  p: {
    fontSize: '12px',
    marginBottom: '1rem',
  },
  ol: {
    paddingLeft: 20,
    paddingBottom: 50,
    margin: 0,
    fontSize: '12px',
  },
  li: {
    padding: 0,
    margin: '20px 0 0',
  },
  bold: {
    fontWeight: 'bold',
  },
});
